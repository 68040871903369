import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Code from "./code";
import Confirmation from "./confirmation";
import Iban from "./iban";
import Loading from "../components/loading";
import Footer from "../components/Footer";
import Recap from "./recap";
import { ErrorPage } from "../components/ErrorPage";

export default function Root() {
  const [isLoading, setIsLoading] = useState(true);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Code />,
      hasErrorBoundary: false,
      errorElement: <ErrorPage />,
    },
    {
      path: "/:codeRecuperation",
      element: <Code />,
      hasErrorBoundary: false,
      errorElement: <ErrorPage />,
    },
    {
      path: "/iban",
      element: <Iban />,
      hasErrorBoundary: false,
      errorElement: <ErrorPage />,
    },
    {
      path: "/recapitulatif",
      element: <Recap />,
      hasErrorBoundary: false,
      errorElement: <ErrorPage />,
    },
    {
      path: "/confirmation",
      element: <Confirmation />,
      hasErrorBoundary: false,
      errorElement: <ErrorPage />,
    },
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  } else {
    return (
      <div>
        <RouterProvider router={router} />
        <Footer />
      </div>
    );
  }
}
