import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import Globe from "../assets/img/globe.svg";
import Button from "../components/Button";
import { checkIbanFunction } from "../functions";
import ErrorDiv from "../components/ErrorDiv";

export default function Iban() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [iban, setIban] = useState<string>("");
  const [saveIban, setSaveIban] = useState<any>(false);
  const [error, setError] = useState<any>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const navigate = useNavigate();

  const redirectToHome = async () => {
    await navigate("/");
  };

  const checkIban = async () => {
    try {
      setIsLoading(true);
      const bankData = await checkIbanFunction(iban.replace(/\s/g, ""));
      await window.localStorage.setItem("iban", iban.replace(/\s/g, ""));
      await window.localStorage.setItem("bankData", JSON.stringify(bankData));
      await window.localStorage.setItem("saveIban", saveIban);
      setIsLoading(false);
      await navigate("/recapitulatif");
    } catch (e) {
      setIsLoading(false);
      setShowError(true);
      setError(e);
    }
  };

  useEffect(() => {
    let don: any = window.localStorage.getItem("don");
    don = JSON.parse(don);
    const code = window.localStorage.getItem("codeRecuperation");
    const savedIban = don.beneficiaire.iban ?? null;
    if (!code || !don) {
      redirectToHome().then((r) => {});
    }
    if (savedIban) {
      setIban(savedIban);
      setSaveIban(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {showError && (
        <ErrorDiv error={error} close={() => setShowError(false)} />
      )}
      <div className="page-container">
        <div className="page-content">
          <div
            style={{
              height: "7vh",
            }}
          >
            <img
              src={Logo}
              style={{ aspectRatio: "contain", height: "100%" }}
              alt="Dimbl logo"
            />
          </div>
          <h2
            style={{ marginTop: "5vh", marginBottom: "5vh" }}
            className="text-bold"
          >
            Sur quel compte souhaitez-vous <br /> recevoir votre don ?
          </h2>
          <img
            src={Globe}
            style={{ aspectRatio: "contain", height: "100%" }}
            alt="Dimbl logo"
          />
          <p style={{ marginTop: "2vh", marginBottom: "2vh" }}>
            Veuillez saisir votre IBAN
          </p>
          <input
            className="input"
            value={iban}
            onChange={(e) => {
              if (error) {
                setShowError(false);
                setError(null);
              }
              setSaveIban(false);
              let newValue = e.target.value;
              newValue = newValue.replace(/\s/g, "");
              setIban(newValue);
            }}
            style={{
              fontSize: 20,
              border: error ? "1px solid #FF7C7C" : "",
              color: error ? "#FF7C7C" : "",
            }}
          />
          <p style={{ marginTop: "2vh", marginBottom: "2vh", fontSize: 12 }}>
            Votre IBAN apparaît sur votre Relevé d’Identité Bancaire
          </p>
          <div style={{ maxWidth: 360, margin: "auto", textAlign: "left" }}>
            <div className="checkbox-row" style={{ marginTop: "4vh" }}>
              <input
                type="checkbox"
                className="checkbox"
                value={saveIban}
                onChange={(e) => setSaveIban(e.target.checked)}
                checked={saveIban}
              />
              <p>Enregistrer mon IBAN en toute sécurité</p>
            </div>
            <p style={{ fontSize: 12, lineHeight: "26px" }}>
              Vous n’aurez plus à le saisir et vous recevrez plus vite les
              prochains dons.
            </p>
          </div>
          <Button
            text="Suivant"
            disabledCondition={!iban || iban?.length < 27 || error}
            onClick={async () => await checkIban()}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
}
