import ErrorCross from "../assets/img/errorCross.svg";
import Cross from "../assets/img/cross.svg";
import React from "react";

const ErrorDiv = ({ error, close }: any) => {
  return (
    <div className="error-div">
      <div className="error-div-content">
        <img
          src={ErrorCross}
          style={{ marginRight: 10, width: "10%", maxWidth: 37 }}
          alt=""
        />
        <div style={{ width: "90%" }}>
          <p className="text-bold">
            {error?.details?.dimblMessage ?? "Une erreur est survenue"}
          </p>
          <p>{error?.details?.details ?? "Veuillez réessayer"}</p>
        </div>
        <img
          src={Cross}
          className="cross"
          style={{ marginRight: 10, width: "10%", maxWidth: 19 }}
          alt=""
          onClick={() => close()}
        />
      </div>
    </div>
  );
};

export default ErrorDiv;
