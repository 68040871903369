import Logo from "../assets/img/logo.png";
import Loader from "../assets/lottie/loader.json";
import Lottie from "lottie-react";

const Loading = () => {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <div
        style={{
          height: "10vh",
        }}
      >
        <img
          src={Logo}
          style={{ aspectRatio: "contain", height: "100%" }}
          alt="Dimbl logo"
        />
      </div>
      <Lottie
        animationData={Loader}
        style={{ height: "25vh", marginTop: "-5vh" }}
      />
    </div>
  );
};

export default Loading;
