import Logo from "../assets/img/logo.png";
import Button from "./Button";
import React from "react";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="page-container">
      <div className="page-content">
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{
              height: "10vh",
            }}
          >
            <img
              src={Logo}
              style={{ aspectRatio: "contain", height: "100%" }}
              alt="Dimbl logo"
            />
          </div>
          <h1 className="text-bold">Une erreur est survenue</h1>
          <p style={{ marginTop: "2vh" }}>
            Veuillez nous excuser pour la gêne occasionnée
          </p>
          <div style={{ marginTop: "0vh" }}>
            <Button
              text="Retourner à l'accueil"
              disabledCondition={false}
              onClick={async () => await navigate("/")}
              isLoading={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
