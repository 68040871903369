import Loader from "../assets/lottie/loader.json";
import Lottie from "lottie-react";

type ButtonProps = {
  text: string;
  disabledCondition: any;
  onClick: any;
  isLoading: boolean;
  outlined?: boolean;
};
const Button = ({
  text,
  disabledCondition,
  onClick,
  isLoading,
  outlined = false,
}: ButtonProps) => {
  return (
    <button
      className={
        disabledCondition
          ? "button button-disabled"
          : isLoading || outlined
          ? "button button-loading"
          : "button"
      }
      style={{ marginTop: "2vh" }}
      disabled={disabledCondition || isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <Lottie
          animationData={Loader}
          style={{ height: "25vh", marginTop: "-10vh", marginBottom: "-10vh" }}
        />
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
