import React, { useEffect, useState } from "react";
import Logo from "../assets/img/logo.png";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import Globe from "../assets/img/globe.svg";
import ErrorDiv from "../components/ErrorDiv";
import { makeTransfer } from "../functions";

type BankData = {
  iban: string;
  bic: string;
  name: string;
};

export default function Recap() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [donData, setDonData] = useState<any>(null);
  const [data, setData] = useState<BankData>({ bic: "", iban: "", name: "" });
  const [error, setError] = useState<any>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [acceptUgc, setAcceptUgc] = useState<any>();
  const [verified, setVerified] = useState<any>();
  const navigate = useNavigate();

  const createTransfer = async () => {
    try {
      setIsLoading(true);
      let don: any = window.localStorage.getItem("don");
      don = JSON.parse(don);
      const saveIban: any = window.localStorage.getItem("saveIban") ?? false;
      await makeTransfer(data.iban, don, saveIban);
      setIsLoading(false);
      await navigate("/confirmation");
    } catch (e) {
      setIsLoading(false);
      setShowError(true);
      setError(e);
    }
  };

  useEffect(() => {
    let bank = window.localStorage.getItem("bankData");
    let don: any = window.localStorage.getItem("don");
    if (!bank || !don) {
      navigate("/");
    }
    // @ts-ignore
    const bankData: BankData = JSON.parse(bank);
    setDonData(JSON.parse(don));
    setData(bankData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showError && (
        <ErrorDiv error={error} close={() => setShowError(false)} />
      )}
      <div className="page-container">
        <div className="page-content">
          <div
            style={{
              height: "7vh",
            }}
          >
            <img
              src={Logo}
              style={{ aspectRatio: "contain", height: "100%" }}
              alt="Dimbl logo"
            />
          </div>
          <h2
            style={{ marginTop: "5vh", marginBottom: "3vh" }}
            className="text-bold"
          >
            Vérifiez vos informations
          </h2>
          <img
            src={Globe}
            style={{ aspectRatio: "contain", height: "100%" }}
            alt="Dimbl logo"
          />

          <div className="bank-info-container">
            <div className="bank-info-row">
              <p className="bank-info-name">Votre nom</p>
              <input
                className="input bank-info-input iban"
                value={
                  donData?.beneficiaire?.prenom +
                  " " +
                  donData?.beneficiaire?.nom
                }
                style={{
                  fontSize: 14,
                  width: "70%",
                  maxWidth: 244,
                  height: 30,
                }}
                disabled={true}
              />
            </div>
            <div className="bank-info-row">
              <p className="bank-info-name">Votre IBAN</p>
              <input
                className="input bank-info-input iban"
                value={data.iban}
                style={{
                  fontSize: 14,
                  width: "70%",
                  maxWidth: 244,
                  height: 30,
                }}
                disabled={true}
              />
            </div>
            <div className="bank-info-row">
              <p className="bank-info-name">Votre BIC</p>
              <input
                className="input bank-info-input"
                value={data.bic}
                style={{
                  fontSize: 14,
                  width: "70%",
                  maxWidth: 244,
                  height: 30,
                }}
                disabled={true}
              />
            </div>
            <div className="bank-info-row">
              <p className="bank-info-name">Votre banque</p>
              <input
                className="input bank-info-input"
                value={data.name}
                style={{
                  fontSize: 14,
                  width: "70%",
                  maxWidth: 244,
                  height: 30,
                }}
                disabled={true}
              />
            </div>
          </div>

          <div style={{ maxWidth: 400, margin: "auto", textAlign: "left" }}>
            <div className="checkbox-row" style={{ marginTop: "4vh" }}>
              <input
                type="checkbox"
                className="checkbox"
                value={verified}
                onChange={(e) => setVerified(e.target.checked)}
              />
              <p>J'atteste que ces informations sont correctes</p>
            </div>
            <div className="checkbox-row" style={{ marginTop: "4vh" }}>
              <input
                type="checkbox"
                className="checkbox"
                value={acceptUgc}
                onChange={(e) => setAcceptUgc(e.target.checked)}
              />
              <p>
                J’accepte les{" "}
                <a
                  href="https://dimbl.org/cgu"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#DC58BF" }}
                  className="text-bold"
                >
                  Conditions Générales d’Utilisation
                </a>{" "}
                et la{" "}
                <a
                  href="https://dimbl.org/confidentialite"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#DC58BF" }}
                  className="text-bold"
                >
                  Politique de confidentialité
                </a>{" "}
                de DIMBL
              </p>
            </div>
          </div>

          <div className="button-container">
            <Button
              text="Modifier mon IBAN"
              disabledCondition={false}
              onClick={async () => await navigate("/iban")}
              isLoading={false}
              outlined={true}
            />
            <div style={{ width: "20px" }}></div>
            <Button
              text="Récupérer mon don"
              disabledCondition={!acceptUgc || !verified}
              onClick={async () => await createTransfer()}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
