import React, { useEffect, useState } from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import Globe from "../assets/img/globe.svg";
import Button from "../components/Button";
import Loading from "../components/loading";
import { getDonation } from "../functions";
import ErrorDiv from "../components/ErrorDiv";

export default function Code() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [processingFirstSearch, setProcessingFirstSearch] =
    useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  let { codeRecuperation } = useParams();
  const navigate = useNavigate();

  const clearStorage = () => {
    window.localStorage.removeItem("codeRecuperation");
    window.localStorage.removeItem("iban");
    window.localStorage.removeItem("don");
    window.localStorage.removeItem("bankData");
    window.localStorage.removeItem("saveIban");
  };

  const redirectToHome = () => {
    clearStorage();
    return redirect("/");
  };

  const findDonation = async (codeRecuperation: string) => {
    setCode(codeRecuperation);
    setIsLoading(true);
    if (codeRecuperation) {
      try {
        const donation = await getDonation(codeRecuperation);
        setIsLoading(false);
        setProcessingFirstSearch(false);
        await window.localStorage.setItem("codeRecuperation", codeRecuperation);
        await window.localStorage.setItem("don", JSON.stringify(donation));
        await navigate("/iban");
      } catch (e) {
        setIsLoading(false);
        setProcessingFirstSearch(false);
        setShowError(true);
        setError(e);
      }
    }
  };

  useEffect(() => {
    clearStorage();
    if (codeRecuperation) {
      if (codeRecuperation.length !== 10) {
        redirectToHome();
        setProcessingFirstSearch(false);
      } else {
        if (!isProcessing) {
          setIsProcessing(true);
          setCode(codeRecuperation);
          findDonation(codeRecuperation).then();
        }
      }
    } else {
      setProcessingFirstSearch(false);
    }
    // eslint-disable-next-line
  }, []);

  if (processingFirstSearch) {
    return (
      <div
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <>
      {showError && (
        <ErrorDiv error={error} close={() => setShowError(false)} />
      )}
      <div className="page-container">
        <div className="page-content">
          <div
            style={{
              height: "7vh",
            }}
          >
            <img
              src={Logo}
              style={{ aspectRatio: "contain", height: "100%" }}
              alt="Dimbl logo"
            />
          </div>
          <h2
            style={{ marginTop: "5vh", marginBottom: "5vh" }}
            className="text-bold"
          >
            Récupérez votre don ici
          </h2>
          <img
            src={Globe}
            style={{ aspectRatio: "contain", height: "100%" }}
            alt="Dimbl logo"
          />
          <p style={{ marginTop: "2vh", marginBottom: "5vh" }}>
            Veuillez saisir le code présent dans le SMS que vous avez reçu
          </p>
          <input
            className="input text-bold"
            autoCapitalize="off"
            style={{
              border: error ? "1px solid #FF7C7C" : "",
              color: error ? "#FF7C7C" : "",
            }}
            maxLength={10}
            value={code}
            onChange={(e) => {
              if (error) {
                setShowError(false);
                setError(null);
              }
              setCode(e.target.value);
            }}
          />
          <div style={{ marginTop: "5vh" }}>
            <p style={{ textAlign: "left", marginBottom: "2vh" }}>
              Le code à saisir se trouve à la fin du SMS que vous avez reçu:
            </p>
            <p style={{ textAlign: "left" }}>
              Bonne nouvelle ! Vous venez de recevoir un don d’un montant de
              10€. Cliquez sur le lien ci-après pour récupérer votre argent
              https://recup.dimbl.org/
              <span className="text-pink text-bold">A1b2C3d4E5</span>
            </p>
          </div>
          <Button
            text="Continuer"
            disabledCondition={!code || code?.length < 10 || error}
            onClick={async () => await findDonation(code)}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
}
