const Footer = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "5vh",
        gap: "50px",
        flexWrap: "wrap",
      }}
    >
      <a
        href="https://dimbl.org/mentions-legales"
        target="_blank"
        rel="noreferrer"
      >
        Mentions légales
      </a>
      <a href="https://dimbl.org/cgu" target="_blank" rel="noreferrer">
        CGU
      </a>
      <a
        href="https://dimbl.org/confidentialite"
        target="_blank"
        rel="noreferrer"
      >
        Confidentialité
      </a>
      <a href="https://dimbl.org/faq" target="_blank" rel="noreferrer">
        FAQ
      </a>
      <a
        href="mailto:unsubscribe@dimbl.org?subject=Désinscription"
        rel="noreferrer"
      >
        Se désinscrire
      </a>
    </div>
  );
};

export default Footer;
