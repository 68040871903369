import firebase from "firebase/compat/app";
import 'firebase/compat/functions';

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
});


let dimblFunctions = app.functions('europe-west1');

if (false) {
  dimblFunctions.useEmulator('0.0.0.0', 5001);
}

export {app, dimblFunctions};

