import { dimblFunctions } from './services/firebase/firebase-config';

export const getDonation = async (donationCode: string) => {
  try {
    const result = await dimblFunctions.httpsCallable('getDonation')({
      code: donationCode,
    });
    return result.data;
  } catch (e) {
    throw e;
  }
};

export const checkIbanFunction = async (iban: string) => {
  try {
    let user: any = await window.localStorage.getItem('don');
    user = JSON.parse(user);
    user = user.beneficiaire;
    const userId = user.id;
    const result = await dimblFunctions.httpsCallable('checkIban')({
      iban,
      userId,
    });
    return result.data;
  } catch (e) {
    throw e;
  }
};

export const makeTransfer = async (
  iban: string,
  don: string,
  saveIban: boolean
) => {
  try {
    const result = await dimblFunctions.httpsCallable('createTransferIntent')({
      iban,
      don,
      saveIban,
    });
    return result.data;
  } catch (e) {
    throw e;
  }
};
