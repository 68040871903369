import React, { useEffect } from "react";
import Logo from "../assets/img/logo.png";
import ConfirmationPicture from "../assets/img/confirmation.svg";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

export default function Confirmation() {
  const navigate = useNavigate();

  useEffect(() => {
    window.localStorage.removeItem("codeRecuperation");
    window.localStorage.removeItem("iban");
    window.localStorage.removeItem("don");
    window.localStorage.removeItem("bankData");
    window.localStorage.removeItem("saveIban");
  }, []);

  return (
    <div className="page-container">
      <div className="page-content">
        <div
          style={{
            height: "7vh",
          }}
        >
          <img
            src={Logo}
            style={{ aspectRatio: "contain", height: "100%" }}
            alt="Dimbl logo"
          />
        </div>
        <h2
          style={{ marginTop: "5vh", marginBottom: "5vh" }}
          className="text-bold"
        >
          C'est en route !
        </h2>
        <img
          src={ConfirmationPicture}
          style={{ aspectRatio: "contain", height: "100%" }}
          alt="Dimbl logo"
        />
        <p style={{ marginTop: "2vh", marginBottom: "2vh" }}>
          Votre don est en cours d'acheminement vers votre <br /> compte
          bancaire
        </p>

        <Button
          text="Récupérer un autre don"
          disabledCondition={false}
          onClick={async () => await navigate("/")}
          isLoading={false}
        />
      </div>
    </div>
  );
}
